import PropTypes from 'prop-types';
import Iconify from '@heyjob-dash/shared/components/iconify/index';
import CustomPopover from '@heyjob-dash/shared/components/custom-popover/index';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';

import { usePopover } from 'src/components/custom-popover';

import { useAuthContext } from '../../auth/hooks/index';

// ----------------------------------------------------------------------

export function WorkspacesPopover({ data = [], sx, ...other }) {
  const popover = usePopover();

  const { user, selectCompany } = useAuthContext();

  const mediaQuery = 'sm';

  const handleChangeWorkspace = (option) => {
    selectCompany(option.id);
    popover.onClose();
  };

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={(e) => popover.onOpen(e)}
        sx={{
          py: 0.5,
          gap: { xs: 0.5, [mediaQuery]: 1 },
          ...sx
        }}
        {...other}
      >

        <Avatar alt={user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.name}
                src={user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.logo_url}
                sx={{ width: 24, height: 24 }} />

        <Box
          component="span"
          sx={{
            typography: 'subtitle2',
            display: { xs: 'none', [mediaQuery]: 'inline-flex' }
          }}
        >
          {user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.name}
        </Box>

        <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
      </ButtonBase>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="top-left"
      >
        <MenuList sx={{ width: 240 }}>
          {data.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === user?.data?.current_company}
              onClick={() => handleChangeWorkspace(option)}
              sx={{ height: 48 }}
            >
              <Box sx={{mr: 1}}>
                <Avatar alt={option.name} src={option.logo} sx={{ width: 24, height: 24, '& svg.MuiSvgIcon-root': { marginRight: 0 } }} />
              </Box>

              <Box component="span" sx={{ flexGrow: 1 }}>
                {option.name}
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
}

WorkspacesPopover.propTypes = {
  data: PropTypes.array,
  sx: PropTypes.object
};
