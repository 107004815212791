import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/HomePage'));
const CompaniesPage = lazy(() => import('../../pages/company/CompaniesPage'));
const CompanyPage = lazy(() => import('../../pages/company/CompanyPage'));
const HiringPage = lazy(() => import('src/pages/HiringPage'));
const TerminationsPage = lazy(() => import('src/pages/TerminationsPage'));
const LettersPage = lazy(() => import('src/pages/LettersPage'));
const HelpDeskPage = lazy(() => import('src/pages/HelpDeskPage'));
const NewsPage = lazy(() => import('src/pages/NewsPage'));
const CreateNewsPage = lazy(() => import('src/pages/CreateNewsPage'));
const PopupNoticePage = lazy(() => import('../../pages/PopupNoticePage'));
const FAQLearningPage = lazy(() => import('src/pages/FAQLearningPage'));
const CompanyExternalLinksPage = lazy(() => import('../../pages/CompanyExternalLinksPage'));
const EmployeeExternalLinksPage = lazy(() => import('../../pages/EmployeeExternalLinksPage'));
const UploadDocumentsPage = lazy(() => import('src/pages/UploadDocumentiPage'));

const EmployeesPage = lazy(() => import('src/pages/employee/AnagrafichePage'));
const CompanyAccountPage = lazy(() => import('src/pages/CompanyAccountPage'));
const CompanyConfigPage = lazy(() => import('src/pages/CompanyConfigPage'));
const CreateCompanyPage = lazy(() => import('src/pages/company/CreateCompanyPage'));
const CompanyPayrollDataPage = lazy(() => import('src/pages/CompanyPayrollDataPage'));
const CompanyClockingPlacesPage = lazy(() => import('../../pages/CompanyClockingPlacesPage'));
const CreateAnagraficaPage = lazy(() =>
  import('src/pages/employee/CreateAnagraficaPage')
);
const TimesheetPage = lazy(() => import('src/pages/timesheet/TimesheetPage'));
const DocumentsPage = lazy(() => import('src/pages/DocumentsPage'));
const ExpenseReportsPage = lazy(() => import('src/pages/ExpenseReportsPage'));
const ScadenzarioPage = lazy(() => import('src/pages/ScadenzarioPage'));
const ConfigPage = lazy(() => import('src/pages/ConfigPage'));
const ImportPage = lazy(() => import('src/pages/ImportPage'));
const TimesheetCategoryDetail = lazy(() => import('src/pages/TimesheetCategoryDetail'));
const AccountPage = lazy(() => import('src/pages/AccountPage'));

const EmployeePage = lazy(() => import('src/pages/employee/AnagraficaPage'));
const UsersPage = lazy(() => import('src/pages/UsersPage'));
const ConsultantsPage = lazy(() => import('src/pages/ConsultantsPage'));
const ManagersPage = lazy(() => import('../../pages/manager/ManagersPage'));
const ConsultantDetail = lazy(() => import('src/pages/ConsultantDetail'));
const ManagerPage = lazy(() => import('../../pages/manager/ManagerPage'));
const MonthlyTimesheetPage = lazy(() =>
  import('src/pages/timesheet/MonthlyTimesheetPage')
);
const TimeProfileDetail = lazy(() => import('src/pages/TimeProfileDetail'));
const ImportTypeDetail = lazy(() => import('src/pages/ImportTypeDetail'));
const TimesheetExtraDetail = lazy(() => import('src/pages/TimesheetExtraDetail'));
const ImportDataDetail = lazy(() => import('../../pages/ImportDataDetail'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'companies', element: <CompaniesPage /> },
      { path: 'companies/new', element: <CreateCompanyPage /> },
      { path: 'companies/:company_id', element: <CompanyPage /> },
      { path: 'hiring', element: <HiringPage /> },
      { path: 'terminations', element: <TerminationsPage /> },
      { path: 'letters', element: <LettersPage /> },
      { path: 'help-desk', element: <HelpDeskPage /> },
      { path: 'news', element: <NewsPage /> },
      { path: 'news/new', element: <CreateNewsPage /> },
      { path: 'news/:news_id', element: <CreateNewsPage /> },
      { path: 'popup-notices', element: <PopupNoticePage />},
      { path: 'faq-e-learning', element: <FAQLearningPage />},
      { path: 'external-links', element: <CompanyExternalLinksPage />},
      { path: 'employee-external-links', element: <EmployeeExternalLinksPage />},
      { path: 'upload-documents', element: <UploadDocumentsPage />},
      { path: 'config', element: <ConfigPage /> },
      { path: 'import', element: <ImportPage /> },
      { path: 'consultants', element: <ConsultantsPage /> },
      { path: 'consultants/:consultant_email', element: <ConsultantDetail /> },
      { path: 'managers', element: <ManagersPage /> },
      { path: 'managers/:manager_id', element: <ManagerPage /> },
      { path: 'users', element: <UsersPage /> },
      { path: 'account', element: <AccountPage /> },
      { path: 'config/timesheet-categories/:short_code', element: <TimesheetCategoryDetail /> },
      { path: 'config/time-profiles/:time_profile_id', element: <TimeProfileDetail /> },
      { path: 'config/timesheet-extra/:extra_id', element: <TimesheetExtraDetail /> },

      { path: 'config/import-types/:import_type_id', element: <ImportTypeDetail /> },

      { path: 'import/:type/:import_id', element: <ImportDataDetail /> },

      { path: 'company/employees', element: <EmployeesPage /> },
      { path: 'company/employees/new', element: <CreateAnagraficaPage /> },
      { path: 'company/employees/:employee_id', element: <EmployeePage /> },
      { path: 'company/timesheet', element: <TimesheetPage /> },
      { path: 'company/timesheet/:year/:month', element: <TimesheetPage /> },
      {
        path: 'company/timesheet/:year/:month/:employee_id',
        element: <MonthlyTimesheetPage />
      },
      { path: 'company/documents', element: <DocumentsPage /> },
      { path: 'company/timetable', element: <ScadenzarioPage /> },
      { path: 'company/note-spese', element: <ExpenseReportsPage /> },
      { path: 'company/payroll-data', element: <CompanyPayrollDataPage /> },
      { path: 'company/clocking-places', element: <CompanyClockingPlacesPage /> },
      { path: 'company/config', element: <CompanyConfigPage /> },
      { path: 'company/account', element: <CompanyAccountPage /> },
    ]
  }
];
