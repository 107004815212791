// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: ''
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/login`,
      verify: `${ROOTS.AUTH}/verify`,
      register: `${ROOTS.AUTH}/register`,
      newPassword: `${ROOTS.AUTH}/new-password`,
      forgotPassword: `${ROOTS.AUTH}/forgot-password`,
      firstAccess: `${ROOTS.AUTH}/first-access`
    }
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/`,
    companies: `${ROOTS.DASHBOARD}/companies`,
    newCompany: `${ROOTS.DASHBOARD}/companies/new`,
    hiring: `${ROOTS.DASHBOARD}/hiring`,
    terminations: `${ROOTS.DASHBOARD}/terminations`,
    letters: `${ROOTS.DASHBOARD}/letters`,
    helpDesk: `${ROOTS.DASHBOARD}/help-desk`,
    news: `${ROOTS.DASHBOARD}/news`,
    newNews: `${ROOTS.DASHBOARD}/news/new`,
    popupNotices: `${ROOTS.DASHBOARD}/popup-notices`,
    externalLinks: `${ROOTS.DASHBOARD}/external-links`,
    employeeExternalLinks: `${ROOTS.DASHBOARD}/employee-external-links`,
    faqElearning: `${ROOTS.DASHBOARD}/faq-e-learning`,
    uploadDocuments: `${ROOTS.DASHBOARD}/upload-documents`,
    account: `${ROOTS.DASHBOARD}/account`,
    users: `${ROOTS.DASHBOARD}/users`,
    config: `${ROOTS.DASHBOARD}/config`,
    import: `${ROOTS.DASHBOARD}/import`,
    consultants: `${ROOTS.DASHBOARD}/consultants`,
    managers: `${ROOTS.DASHBOARD}/managers`
  },
  // COMPANY
  company: {
    employees: `${ROOTS.DASHBOARD}/company/employees`,
    newEmployee: `${ROOTS.DASHBOARD}/company/employees/new`,
    timesheet: `${ROOTS.DASHBOARD}/company/timesheet`,
    calendar: `${ROOTS.DASHBOARD}/company/timetable`,
    documents: `${ROOTS.DASHBOARD}/company/documents`,
    notices: `${ROOTS.DASHBOARD}/company/comunicazioni`,
    payrollData: `${ROOTS.DASHBOARD}/company/payroll-data`,
    expenseReports: `${ROOTS.DASHBOARD}/company/note-spese`,
    clockingPlaces: `${ROOTS.DASHBOARD}/company/clocking-places`,
    account: `${ROOTS.DASHBOARD}/company/account`,
    config: `${ROOTS.DASHBOARD}/company/config`
  }
};
